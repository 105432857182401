export const config = {
  ws: {
    host: 'wss://stream.luxee.date'
  },
  wsNotify: {
    host: 'wss://stream.luxee.date'
  },
  domain: {
    imgDomain: 'https://img.luxee.date'
  }
};