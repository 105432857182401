import React from "react";
import styles from './styles.module.scss';
import {useSelector} from "react-redux";

function Header({setLogoutModal}) {
    const usersCount = useSelector((state) => state.chat.countInfo.count);

    return (
        <header className={styles.header}>
            <div className={styles.container}>
                <div className={styles.counter}>
                    <span className={styles.count}>{usersCount ?? 0}</span>
                </div>
                <button className={styles.btn + " " + styles.btnLogout } onClick={() => setLogoutModal(true)}>
                    Log Out
                </button>
            </div>
        </header>
    );
}

export default Header;
