import React from "react";
import {Modal, ModalTitle} from '../index';

import styles from './styles.module.scss';

export default function ErrorModal({ show, close, error }) {

    return (
        <Modal show={show} close={close} error={error} customClass={'error-modal'}>
            <ModalTitle text={error.title ? error.title : "Oops, something went wrong!"}/>
            <div
                className={styles.customModalDescription}
                dangerouslySetInnerHTML={{__html: (error.text ? error.text : `<span>Please, try to reload page</span>`)}}
            />
            { !error?.disconnected && !error.hideClose && <div className={styles.customModalFooter}>
                <button type="button" className={styles.btn +' '+styles.btnPrimary} onClick={close}>{error.btnText ? error.btnText : 'OK'}</button>
            </div>}
        </Modal>
    );
}