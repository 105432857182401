import { types } from "../types/chat";

export const initialState = {
    countInfo: {
        count: 0,
        time: 0
    },
    users: [],
    messages: []
}

export function chat(state = initialState, action) {

    switch (action.type) {
        case types.SET_USERS_COUNT:
            return {...state, countInfo: {...action.payload, count: parseInt(action.payload.count) - 1}};
        case types.SET_USER_TO_STREAM:
            return {...state, users: state.users.push(action.payload)};
        case types.ADD_MSG:
            return {...state, messages: [action.payload, ...state.messages]};
        default:
            return state;
    }
}
