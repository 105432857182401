import html2canvas from 'html2canvas';
import {PLAYER_ID} from '../constants/player';
import {sendScreenShot} from '../services/screenshot';
import {getQueryParam} from './locationHelper';

/**
 * Make screenshot of player
 * @return {null}
 */
export const makeScreenShot = () => {
    const player = document.getElementById(PLAYER_ID).getElementsByTagName('video');
    const token = getQueryParam('auth');

    if (!player || !player[0] || !token) {
        return null;
    }

    try {
        const canvas = document.createElement('canvas');
        canvas.width = player[0].videoWidth;
        canvas.height = player[0].videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(player[0], 0, 0, canvas.width, canvas.height);
        const context = canvas.toDataURL('image/jpeg', 1);

        if (context) {
            sendScreenShot(token, context)
                .then((response) => {
                    console.log('Response', response);
                })
                .catch((error) => {
                    console.error(error.toString());
                })
        }
    } catch (e) {
        console.error(e.toString());
    }
};
