import {useEffect, useRef, useState} from "react";
import {io} from "socket.io-client";
import {config} from "../config/config";
import {getTabId} from "../helpers";
import {
    ONLINE_PING_INTERVAL,
    ONLINE_TAB_STATUS_ACTIVE,
    ONLINE_TAB_STATUS_DISABLED
} from "../constants/notification";

let socket = null;

export default function useNotification() {
    const [isInit, setIsInit] = useState(false);
    const [token, setToken] = useState(null);
    const pingInterval = useRef(null);
    const activityTime = useRef(null);

    useEffect(() => {
        userActivity();
        document.addEventListener('mousemove', userActivity);
        document.addEventListener('touchstart', userActivity);
        document.addEventListener('keypress', userActivity);

        return () => {
            document.removeEventListener('mousemove', userActivity);
            document.removeEventListener('touchstart', userActivity);
            document.removeEventListener('keypress', userActivity);
        };
    }, []);

    useEffect(() => {
        if (isInit && token && !socket) {
            initWs();
        }
    }, [token, isInit]);

    function userActivity() {
        activityTime.current = (new Date()).getTime();
    }

    function isActiveTab() {
        return ((new Date()).getTime() - activityTime.current + 5000) <= (ONLINE_PING_INTERVAL * 1000);
    }

    function init({ notificationToken }) {
        if (isInit || !notificationToken) {
            return;
        }
        setIsInit(true);
        setToken(notificationToken);
    }

    function disconnect(data) {
        if (!isInit) {
            return;
        }

        setIsInit(false);
        cleaPing();
        socket.disconnect();
        socket = null;
    }

    function updateToken({ notificationToken }) {
        setToken(notificationToken);
    }

    function initWs() {
        socket = io(config.wsNotify.host || '', {
            path: '/notify/socket.io',
            pingInterval: 5000,
            pingTimeout: 25000,
            query: {
                token,
                type: 'model'
            },
            transports: ['websocket', 'polling'],
            transportOptions: {
                polling: {
                    extraHeaders: {
                        token,
                    }
                }
            }
        });

        socket.on('connect', function () {
            pingOnline(true);

            pingInterval.current = setInterval(pingOnline, ONLINE_PING_INTERVAL * 1000)
        });

        socket.on('connect_error', function () {
            cleaPing();
        });

        socket.on('disconnect', function () {
            cleaPing();
        });

        socket.on('message', function (message) {});
    }


    function pingOnline(firstTime) {
        socket.emit(
            'online',
            {
                url: window.location.href,
                tabId: getTabId(),
                tabStatus: isActiveTab() ? ONLINE_TAB_STATUS_ACTIVE : ONLINE_TAB_STATUS_DISABLED,
                duration: firstTime ? 0 : ONLINE_PING_INTERVAL
            },
            function (result, error) {}
        );
    }

    function cleaPing() {
        if (pingInterval.current === null) {
            return;
        }

        clearInterval(pingInterval.current);
        pingInterval.current = null;
    }


    return {
        init,
        disconnect,
        updateToken
    };
}
