import axios from "axios/index";

/**
 * Send screenshot as base64 string
 * @param {string} token Invite token
 * @param {string} base64Img Image in base64 format
 * @return {Promise<AxiosResponse<unknown>>} Return result of request as promise
 */
export function sendScreenShot(token, base64Img) {
    return axios.post(
        `/api/snapshot/${token}`,
        {
            img: base64Img
        }
    );
}
