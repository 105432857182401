import { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { getQueryParam } from "../helpers/locationHelper";
import { userActions } from '../redux/actions/user';
import {helpers} from "../helpers";
import AgoraRTC from 'agora-rtc-sdk-ng';
import useNotification from "./useNotification";

export default function useAgora(client, setError) {
  const authToken = getQueryParam('auth');
  const [localVideoTrack, setLocalVideoTrack] = useState(undefined);
  const [remoteUsers, setRemoteUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const { init: initNotification, disconnect: disconnectNotification, updateToken } = useNotification();

  useEffect(() => {
    updateToken(userData);
  }, [userData]);

  async function createLocalTracks(videoConfig) {
    const cameraTrack = await AgoraRTC.createCameraVideoTrack(videoConfig);
    setLocalVideoTrack(cameraTrack);
    return cameraTrack;
  }

  async function init() {
    if (!client) return;

    const cameraTrack = await createLocalTracks();

    (window).client = client;
    (window).videoTrack = cameraTrack;
  }

  async function join(appid, channel, token, uid, socket) {
    if (!client) return;

    setLoading(true);

    socket.emit('ev.can_publish', async (response) => {
      if (!response || !response.status){
        setError({
          title:"Can't join this channel",
          text:'Please, consider checking other tabs or try later',
          btnText:'OK'
        });
        setLoading(false);
        return;
      }

      if (userData.expiredAt*1000 <= new Date().getTime()){
        let newUserData = await helpers.getNewToken(authToken)
            .catch(() => {
              setError({error: true});
              setLoading(false);
              return;
            });

        dispatch(userActions.setUserData(newUserData));
        await client.join(newUserData.appId, newUserData.channel, newUserData.token, newUserData.uid)
            .catch(() => setAgoraFail());

      } else {
        await client.join(appid, channel, token, uid).catch(() => setAgoraFail());
      }
      await publish(socket, token);
      setLoading(false);
    })
  }

  async function leave() {
    if (localVideoTrack) {
      localVideoTrack.stop();
      localVideoTrack.close();
    }
    setRemoteUsers([]);
    await client?.leave().catch(() => setAgoraFail());
  }

  async function publish(socket, token) {
    const cameraTrack = await createLocalTracks();

    setRemoteUsers([]);

    await client?.publish([cameraTrack]).catch(() => setAgoraFail());
    dispatch(userActions.setPublish());
    socket.emit(
        "ev.publish", { token }
    );

    initNotification(userData);
  }

  async function unpublish(socket) {
    setRemoteUsers([]);

    await client?.unpublish().catch(() => setAgoraFail());
    dispatch(userActions.setUnpublish());
    socket.emit("ev.publish_stop");

    disconnectNotification()

    //leave
    setRemoteUsers([]);
    await client?.leave().catch(() => setAgoraFail());
  }

  const setAgoraFail = () => {
    dispatch(userActions.setServiceError(true));
  };

  useEffect(() => {

    if (!client) return;
    setRemoteUsers(client.remoteUsers);

    const handleUserPublished = async (user, mediaType) => {
      await client.subscribe(user, mediaType).catch(() => setAgoraFail());
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
    }
    const handleUserUnpublished = (user) => {
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
    }
    const handleUserJoined = (user) => {
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
    }
    const handleUserLeft = (user) => {
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
    }
    const handleRenewToken = async () => {
      let newUserData = await helpers.getNewToken(authToken)
          .catch(() => {
            setError({error: true});
          });
      if (newUserData && newUserData.token){
        dispatch(userActions.setUserData(newUserData));
        await client.renewToken(newUserData.token).catch(() => setAgoraFail());
        dispatch(userActions.setPublish());
      }
    }
    const handleRejoin = async () => {
      let newUserData = await helpers.getNewToken(authToken)
          .catch(() => {
            setError({error: true});
          });
      if (newUserData && newUserData.token){
        dispatch(userActions.setUserData(newUserData));
        await client.join(newUserData.appId, newUserData.channel, newUserData.token, newUserData.uid)
            .catch(() => setAgoraFail());
        const cameraTrack = await createLocalTracks();
        await client?.publish([cameraTrack]).catch(() => setAgoraFail());
        dispatch(userActions.setPublish());
      }
    }

    client.on('user-published', handleUserPublished);
    client.on('user-unpublished', handleUserUnpublished);
    client.on('user-joined', handleUserJoined);
    client.on('user-left', handleUserLeft);
    client.on("token-privilege-will-expire", handleRenewToken);
    client.on("token-privilege-did-expire", handleRejoin);

    return () => {
      client.off('user-published', handleUserPublished);
      client.off('user-unpublished', handleUserUnpublished);
      client.off('user-joined', handleUserJoined);
      client.off('user-left', handleUserLeft);
    };
  }, [client, authToken]);

  return {
    localVideoTrack,
    init,
    leave,
    join,
    publish,
    unpublish,
    remoteUsers,
    loading,
    setLocalVideoTrack
  };
}
