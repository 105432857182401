import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';

import {getQueryParam} from "./helpers/locationHelper";
import { io } from "socket.io-client";
import { config } from "./config/config";
import { userActions } from './redux/actions/user';
import { chatActions } from './redux/actions/chat';
import { userService } from "./services/user";
import { chatConstants } from "./constants/chat";

import Header from "./components/Header";
import Stream from "./components/Stream";
import Chat from "./components/Chat";
import SpinLoader from "./components/SpinLoader";
import LogoutModal from "./components/Modal/LogoutModal";
import ErrorModal from "./components/Modal/ErrorModal";

import styles from './App.module.scss';

let socket = null;

function App() {
    const token = getQueryParam('auth');
    const [logoutModal, setLogoutModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [unsubscribe, setUnsubscribe] = useState(false);
    const [isLogOut, setIsLogOut] = useState(false);

    const user = useSelector((state) => state.user);
    const countInfo = useSelector((state) => state.chat.countInfo);
    const dispatch = useDispatch();

    const initWs = (token) => {

        socket = io(config.ws.host || '', {
            path: '/stream',
            pingInterval: 5000,
            pingTimeout: 10000,
            transports: ['websocket', 'polling'],
            auth: {
                token: token
            }
        });

        socket
            .on('connect', () => {
                console.log(`connect -> ${socket.id}`)
                if (socket.id){
                    setError(null);
                    dispatch(userActions.setConnectionError(false));
                }
            })
            .on('disconnect', (reason) => {
                console.log(`disconnect -> ${socket.id}`, reason);

                dispatch(userActions.setUnpublish());
                dispatch(userActions.setConnectionError(true));
                if (reason === "io server disconnect") {
                    socket.connect();
                }
            })
            .on('connect_error', () => {
                console.log(`connect_error -> ${socket.id}`)
                dispatch(userActions.setUnpublish());
                dispatch(userActions.setConnectionError(true));
            })
            .on('em.auth', (data) => {
                console.log(`em.auth -> ${socket.id}`, data);
                if (!data.status){
                    setError({text: data.error?.message ?? ''});
                    socket.disconnect();
                    dispatch(userActions.cleanUserData());
                } else {
                    setError(null);
                }
            })
            .on('em.m.unsubscribe', () => {
                setUnsubscribe(true);
            })
            .on('em.m.count_subscribed_users', (data) => {
                if (data.data?.time <= countInfo.time) return;
                console.log(`count_subscribed_users -> `, data);
                dispatch(chatActions.setUsersCount(data.data));
            })
            .on('em.m.new_chat_message', (data) => {
                //TODO: check if user subscribed
                dispatch(chatActions.addMessage(data.data));
            })
            .on('em.m.user_unsubscribed', (data) => {
                dispatch(chatActions.addMessage({user: data.data, action: chatConstants.USER_LEAVE_STREAM}));
            })
            .on('em.m.user_subscribed',(data) => {
                console.log(`em.m.user_subscribed -> `, data);
                dispatch(chatActions.addMessage({user: data.data, action: chatConstants.USER_JOIN_STREAM}));
        })
    }

    const tryToConnect = () => {
        if (!user?.appId && !error)
            userService.fetchAuth(token).then((response) => {
                initWs(token);
                dispatch(userActions.setUserData(response.data.data));
            })
                .catch((err) => {
                    if (err.response?.status >= 500){
                        setError({error: true});
                    } else {
                        setError({text: err.response?.data?.error?.message ?? ''});
                    }
                })
    };

    const logOut = () => {
        setLogoutModal(false);
        setLoading(true);
        userService.logOut(token).then(() => {})
            .finally(() => {
                setIsLogOut(true);
                socket.disconnect();
                setLoading(false);
                setError({
                    title: "Successed",
                    text: 'If you want to stream again you should use new link',
                    hideClose: true
                });
            })
    };

    const clearUserData = () => {
        setError(false);
        dispatch(userActions.cleanUserData());
    };

    useEffect(() => {

        if (!token) {
            alert('Auth token is required.');
            return;
        }
        tryToConnect();

        if (user.isPublish){
            socket && socket
                .on('em.m.is_active_publish', () => {
                    socket.emit('ev.publish_already', {
                        token: token,
                    });
                });
        } else {
            socket && socket
                .off('em.m.is_active_publish');
        }
    });

    return (
        <div className={styles.App}>
            <SpinLoader send={loading} type={'absolute'} />
            <Header
                setLogoutModal={setLogoutModal}
            />
            <div className={styles.content}>
                <div className={styles.container}>
                    {!user.appId ?
                        <SpinLoader send={true} type={'absolute'}/>
                        :
                        <>
                            <Stream
                                userData={user}
                                socket={socket}
                                error={error}
                                setError={setError}
                                isLogOut={isLogOut}
                                unsubscribe={unsubscribe}
                            />
                            <Chat />
                        </>
                    }
                </div>
            </div>
            {logoutModal && <LogoutModal
                    show={logoutModal}
                    close={() => setLogoutModal(false)}
                    logOut = {logOut}
                />}
            {error && !error.disconnected && <ErrorModal
                show={error}
                error={error}
                close={() => error.hideClose ? clearUserData() : setError(null)}
            />}
            {error && error.disconnected && <ErrorModal
                show={error}
                error={{disconnected: true, text: 'Try to reconnect'}}
                close={() => setError(null)}
            />}
        </div>
    );
};

export default App;
