import React from "react";
import { getPhotoUrl } from "../../helpers";
import styles from './styles.module.scss';

function Message({msg}) {
    return (
        <div className={styles.messageWrap}>
            <div className={styles.avatar}>
                {!msg.user.avatar.empty && <div style={{backgroundImage: 'url("' + getPhotoUrl(msg.user.avatar.thumbnail)+ '")'}}/>}
            </div>
            <div className={styles.message}>
                <p className={styles.name}>{msg.user.username}</p>
                <p className={styles.text + (msg.action ? ' '+styles[msg.action] : '')}>{msg.message.body}</p>
            </div>
        </div>
    );
}

export default Message;
