export const chatConstants = {
    //types of message
    MEDIA_TYPE: 2,
    WINK_TYPE: 3,
    GIFT_MEDIA_TYPE: 4,


    USER_JOIN_STREAM: 'join',
    USER_LEAVE_STREAM: 'leave',
};
