import React, {useEffect} from 'react';
import MediaPlayer from '../MediaPlayer';
import SpinLoader from '../SpinLoader';
import AgoraRTC from 'agora-rtc-sdk-ng';
import useAgora from '../../hooks/useAgora';

import styles from './styles.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {userActions} from "../../redux/actions/user";

const client = AgoraRTC.createClient({ codec: 'h264', mode: 'rtc' });

function Stream({socket, unsubscribe, isLogOut, setError, error}) {

    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const {
        localVideoTrack, init, leave, join, unpublish, loading, setLocalVideoTrack
    } = useAgora(client, setError);

    useEffect(() => {

        if (user.appId && socket){
            init();
        }

        return () => {
            console.log('leave');
            dispatch(userActions.setServiceError(false));
            setError(null);
            leave();
        };
    }, []);

    useEffect(() => {

        if (unsubscribe){
            unpublish(socket);
            socket.emit('ev.was_unsubscribe', 'OK');
        }

    }, [unsubscribe]);
    useEffect(() => {

        if (isLogOut){
            unpublish(socket);
            setLocalVideoTrack(null);
        }
    }, [isLogOut]);

    useEffect(() => {
        // If Agora fail
        if (user.serviceError){
            setError({error: true, hideClose: true});
            unpublish(socket);
            socket.disconnect();
        }

    }, [user.serviceError]);

    useEffect(() => {
        if (!user.serviceError && user.connectionError && !error?.disconnected){
            setError({disconnected: true});
            unpublish(socket);
        }
    }, [user.connectionError]);

    return (
        <>
            <div className={styles.call}>
                <div className={styles.playerContainer}>
                    <div className={styles.localPlayerWrapper}>
                        <SpinLoader send={loading} type={'absolute'} />
                        <MediaPlayer videoTrack={localVideoTrack}/>
                    </div>
                    <form className={styles.callForm}>
                        { user.isPublish
                            ? <button type='button' className={styles.btn+ ' ' + styles.finish} onClick={() => { unpublish(socket) }}>Finish Streaming</button>
                            : <button type='button' className={styles.btn} onClick={() => { join(user.appId, user.channel, user.token, user.uid, socket) }}>Start Streaming</button>}
                    </form>
                </div>
            </div>
        </>
    );
}

export default Stream;
