import React from 'react';
import styles from './styles.module.scss';
const MIN_TOP_OFFSET = 24;

export class Modal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFixedTopPosition: false
        };

        this.modal = React.createRef();
        this.setFixedPosition = this.setFixedPosition.bind(this);
        this.lockBody = this.lockBody.bind(this);
    }

    componentDidMount() {
        const { show } = this.props;
        this.setFixedPosition();
        window.addEventListener('resize', this.setFixedPosition);
        window.addEventListener('orientationchange', () => {
            setTimeout(this.setFixedPosition, 200);
        });

        this.lockBody(!show);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { show } = this.props;
        this.setFixedPosition();
        this.lockBody(!show);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setFixedPosition);
        window.removeEventListener('orientationchange', () => {
            setTimeout(this.setFixedPosition, 200);
        });
        this.lockBody(true);
    }

    getTopOffset() {
        const modal = this.modal.current;

        if (!modal || typeof modal.getBoundingClientRect !== "function")
            return null;

        return window.innerHeight - modal.clientHeight;
    }

    setFixedPosition() {
        const topPosition = this.getTopOffset();
        let isFixedToTop = (topPosition !== null && topPosition <= MIN_TOP_OFFSET) ? true : false;

        if (this.state.isFixedTopPosition !== isFixedToTop)
            this.setState({ isFixedTopPosition: isFixedToTop });
    }

    lockBody(hide) {
        const body = document.getElementsByTagName('body'),
            { show, showScroll } = this.props;

        if (body.length === 0 || typeof body[0] === "undefined" || showScroll)
            return;

        if (!hide) {
            if (show)
                body[0].classList.add('hide-scroll');
        } else
            body[0].classList.remove('hide-scroll');
    }

    render() {
        let { show, close, width, customClass, customId, error } = this.props;

        return (
            <div
                id={customId}
                className={ styles.customModal +
                    (this.state.isFixedTopPosition ? styles.fixedTop : '') +
                    ((show) ? "" : " hidden") +
                    (customClass ? ' ' + customClass : '')}
            >
                <div className={styles.fadeModal} onClick={close}/>
                <div
                    className={styles.customModalBody}
                    style={width ? {maxWidth: width} : null}
                    ref={this.modal}
                >
                    {!error?.hideClose && <div className={styles.closeModal} onClick={close} />}
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export function ModalTitle({ text, customClass }) {
    return <div
        className={styles.customModalTitle + (customClass ? ' ' + customClass : '')}
        dangerouslySetInnerHTML={{ __html: text }}
    />
}