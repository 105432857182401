import { types } from '../types/user';
import { SCREENSHOT_INTERVAL_TIME } from '../../constants/screenshot';
import { makeScreenShot } from '../../helpers/screenshotHelper';

const initialState = {
    interval: null
};

export function screenshot(state = initialState, action) {
    switch (action.type) {
        case types.SET_PUBLISH:
            let interval = state.interval;

            if (interval === null) {
                interval = setInterval(() => makeScreenShot(), SCREENSHOT_INTERVAL_TIME * 1000);
            }

            return {...state, ...{interval}};
        case types.SET_UNPUBLISH:
            if (state.interval === null) {
                return state;
            }

            clearInterval(state.interval);
            return {...state, ...{interval: null}}
        default:
            return state;
    }
}
