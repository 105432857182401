import React, {useEffect, useRef, useState} from 'react';
import { useSelector } from "react-redux";
import Message from "../Message";

import styles from './styles.module.scss';

const TABLET_WIDTH = 768;
const MIN_HEIGHT = 200;

function Chat() {
    const [styleAttr, setStyleAttr] = useState({height: 'auto'});
    const usersCount = useSelector((state) => state.chat.countInfo.count);
    const messages = useSelector((state) => state.chat.messages);

    const chatTopRef = useRef(null);
    const chatRef = useRef(null);

    const scrollToBottom = () => {
        chatTopRef.current.scrollIntoView({ behavior: "smooth" })
    }

    const windowResize = () => {
        const ww = window.innerWidth;
        const wrapHeight = (chatRef.current.style && chatRef.current.style.height)
            ? chatRef.current.style.height : null;
        const wh = window.innerHeight;
        const chatHeight = wh - chatRef.current.offsetTop;
        let wrapHeightNew = (ww > TABLET_WIDTH) ? 'auto' : `${chatHeight}px`;

        if (chatHeight < MIN_HEIGHT) {
            wrapHeightNew = `${MIN_HEIGHT}px`;
        }

        if (!wrapHeight || wrapHeightNew !== wrapHeight) {
            setStyleAttr({height: wrapHeightNew})
        }
    };

    useEffect(scrollToBottom, [messages]);

    useEffect(() => {
        window.addEventListener('resize', windowResize);
        window.addEventListener('orientationchange', () => {
            setTimeout(windowResize, 300);
        });
        windowResize();

        return () => {
            window.removeEventListener('resize', windowResize);
            window.removeEventListener('orientationchange', () => {
                setTimeout(windowResize, 300);
            });
        };
    }, []);

    return (
        <div className={styles.chat} style={styleAttr} ref={chatRef}>
            <div className={styles.title}>
                Chat
                <span className={styles.count}>{usersCount ?? 0}</span>
            </div>
            <div className={styles.chatWrapper}>
                <div ref={chatTopRef}/>
                {messages.length
                    ? <div>{
                        messages.map((msg, index) =>
                            <Message key={`${msg.createdAt.toString()}_${index}`} msg={msg}/>)
                    }</div>
                    : <div className={styles.emptyChat}>No messages yet</div>
                }
            </div>
        </div>
    );
}

export default Chat;
