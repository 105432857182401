import axios from "axios/index";

export const userService = {
    fetchAuth,
    renewToken,
    logOut
};

/**
 * Fetch auth data
 *
 * @param token
 * @returns {AxiosPromise<any>}
 */
function fetchAuth(token) {
    return axios.get(`/api/auth/${token}`);
}

/**
 * Fetch new token
 *
 * @param token
 * @returns {AxiosPromise<any>}
 */
function renewToken(token) {
    return axios.get(`/api/renew/${token}`);
}

/**
 * Log out
 *
 * @param token
 * @returns {AxiosPromise<any>}
 */
function logOut(token) {
    return axios.get(`/api/logout/${token}`);
}