import React, { useRef, useEffect } from "react";

import styles from './styles.module.scss';
import {PLAYER_ID} from '../../constants/player';

const MediaPlayer = (props) => {
    const container = useRef(null);
    useEffect(() => {
        if (!container.current) return;

        if (props.videoTrack) props.videoTrack.play(container.current);
        return () => {
            if (props.videoTrack) props.videoTrack.stop();
        };
    }, [container, props.videoTrack]);

    return (
        <div id={PLAYER_ID} ref={container} className={styles.videoPlayer} style={{ width: "100%", height: "auto" }}/>
    );
};

export default MediaPlayer;
