import {types} from "../types/user";

export const userActions = {
    setUserData: (user) => {
        return {
            type: types.SET_USER_DATA,
            payload: user,
        }
    },
    cleanUserData: () => {
        return {
            type: types.CLEAR_USER
        }
    },
    setPublish: () => {
        return {
            type: types.SET_PUBLISH
        }
    },
    setUnpublish: () => {
        return {
            type: types.SET_UNPUBLISH
        }
    },
    setConnectionError: (status) => {
        return {
            type: types.SET_CONNECTION_ERROR,
            payload: status
        }
    },
    setServiceError: (status) => {
        return {
            type: types.SET_SERVICE_ERROR,
            payload: status
        }
    },
};