import { applyMiddleware, createStore, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { user } from "./reducers/user";
import { chat } from "./reducers/chat";
import { screenshot } from "./reducers/screenshot";

const rootReducer = combineReducers({
    user,
    chat,
    screenshot
});

export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunk,
    )
);
