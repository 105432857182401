import {types} from "../types/chat";
import {chatConstants} from "../../constants/chat";
import {playNotifySound} from "../../helpers";

export const chatActions = {
    setUsersCount: (data) => {
        return {
            type: types.SET_USERS_COUNT,
            payload: data,
        }
    },
    setUserToStream: (user) => {
        return {
            type: types.SET_USER_TO_STREAM,
            payload: user,
        }
    },
    addMessage: (msg) => {
        if (msg?.message?.type === chatConstants.MEDIA_TYPE || msg?.message?.type === chatConstants.GIFT_MEDIA_TYPE){
            msg.message = {body: 'Sent a media file'};
        }
        if (msg?.message?.type === chatConstants.WINK_TYPE){
            msg.message = {body: 'Sent a wink'};
        }
        if(msg.action === chatConstants.USER_JOIN_STREAM) {
            msg.createdAt = new Date().getTime();
            msg.message = {body: 'Joined stream'};
            playNotifySound();
        }
        if(msg.action === chatConstants.USER_LEAVE_STREAM){
            msg.createdAt = new Date().getTime();
            msg.message = {body: 'Leave stream'};
        }

        if (!msg.createdAt) {
            msg.createdAt = new Date().getTime();
        }

        if (msg.createdAt.toString().length < 13) {
            msg.createdAt = msg.createdAt * 1000;
        }

        return {
            type: types.ADD_MSG,
            payload: msg,
        }
    },
};
