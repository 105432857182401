import { types } from "../types/user";

export const initialState = {
    appId: "",
    channel: "",
    token: "",
    uid: null,
    isPublish: false,
    expiredAt: 0,
    connectionError: false,
    serviceError: false,
}

export function user(state = initialState, action) {

    switch (action.type) {
        case types.CLEAR_USER:
            return {...initialState};
        case types.SET_USER_DATA:
            return {...initialState, ...action.payload};
        case types.SET_PUBLISH:
            return {...state, isPublish: true};
        case types.SET_UNPUBLISH:
            return {...state, isPublish: false};
        case types.SET_CONNECTION_ERROR:
            return {...state, connectionError: action.payload};
        case types.SET_SERVICE_ERROR:
            return {...state, serviceError: action.payload};
        default:
            return state;
    }
}