import React from 'react';

import styles from './styles.module.scss';

class SpinLoader extends React.Component {
    render() {
        let { send, type, text } = this.props;

        if (!send) {
           return null;
        }

        return (
            <div className={styles.spinLoader + ' '+styles.active + ' ' +(!type ? styles.fixed : type)}>
                <div className={styles.textLoader}>
                    <div className={styles.ldsEllipsis}>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                    </div>
                    <span>{text ? text : 'Loading'}...</span>
                </div>
            </div>
        );
    }
}

export default SpinLoader;