import React from "react";
import {Modal, ModalTitle} from '../index';

import styles from './styles.module.scss';

export default function LogoutModal({ show, close, logOut }) {
    return (
        <Modal show={show} close={close} customClass={'error-modal'}>
            <ModalTitle text={"Are you sure you want to log out?"}/>
            <div className={styles.customModalFooter}>
                <button type="button" className={styles.btn +' '+styles.btnPrimary} onClick={logOut}>Yes</button>
                <button type="button" className={styles.btn +' '+styles.btnDefault} onClick={close}>No</button>
            </div>
        </Modal>
    );
}